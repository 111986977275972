var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kt-login__forgot1 kt-login__switch",
      staticStyle: { "animation-duration": "0.3s" },
    },
    [
      _vm._m(0),
      _c("form", { staticClass: "kt-form", attrs: { action: "" } }, [
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.emailAddress,
                expression: "emailAddress",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder: _vm.$t("generic.labels.email"),
              name: "username",
            },
            domProps: { value: _vm.emailAddress },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.validateEmail({ force: true })
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.emailAddress = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "kt-login__actions" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-brand btn-pill kt-login__btn-primary",
              attrs: { id: "kt_login_forgot_submit" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.validateEmail({ force: true })
                },
              },
            },
            [_vm._v(" Request ")]
          ),
          _vm._v("   "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-pill kt-login__btn-secondary",
              attrs: { id: "kt_login_forgot_cancel" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.switchDisplay("EmailChecker")
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-login__head" }, [
      _c("h3", { staticClass: "kt-login__title" }, [
        _vm._v(" Forgotten Password ? "),
      ]),
      _c("div", { staticClass: "kt-login__desc" }, [
        _vm._v(" Enter your email to reset your password: "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "kt-login__signin kt-login__switch",
      staticStyle: { "animation-duration": "0.3s" },
    },
    [
      _vm._m(0),
      _c(
        "form",
        {
          staticClass: "kt-form",
          attrs: { novalidate: "novalidate" },
          on: { submit: _vm.checkForm },
        },
        [
          _c("div", { staticClass: "input-group" }, [
            _c("div", { staticClass: "w-100" }, [
              _c("input", {
                staticClass: "form-control input-white",
                attrs: { type: "text", name: "username" },
                domProps: { value: _vm.email },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.switchDisplay("EmailChecker")
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "input-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                placeholder: _vm.$t("generic.labels.password"),
                name: "password",
              },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "row kt-login__extra" }, [
            false
              ? _c("div", { staticClass: "col" }, [
                  _c("label", { staticClass: "kt-checkbox" }, [
                    _c("input", {
                      attrs: { type: "checkbox", name: "remember" },
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("pages.login.rememberMe")) + " "
                    ),
                    _c("span"),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "col kt-align-right" }, [
              _c(
                "a",
                {
                  staticClass: "kt-login__link",
                  attrs: { id: "kt_login_forgot", href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.switchDisplay("ForgotPassword")
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("pages.login.forgetPassword")) + " "
                  ),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "kt-login__actions" }, [
            _c("button", { staticClass: "btn btn-info btn-pill btn-lg" }, [
              _c("i", { staticClass: "fa fa-cog" }),
              _vm._v(
                " " + _vm._s(_vm.$t("pages.login.loginWithSyncGrades")) + " "
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-login__head" }, [
      _c("h3", { staticClass: "kt-login__title" }, [
        _vm._v(" Sign In to Sync Grades "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
/* eslint-disable no-undef */
import { mapState } from 'vuex';

export default {
    name: 'GoogleRedirect',
    data() {
        return {
        };
    },
    props: [
        'googleClientId',
    ],
    computed: {
        ...mapState({
            email: (state) => state.login.emailAddress,
            redirect: (state) => state.login.redirect,
        }),
    },
    mounted() {
        google.accounts.id.initialize({
            client_id: this.googleClientId,
            callback: window.handleCredentialResponse,
        });
        google.accounts.id.renderButton(
            document.getElementById('googleButton'), {
                theme: 'dark',
                size: 'large',
                text: 'sign_in_with',
                shape: 'rectangular',
                width: '400',
                longtitle: true,
            },
        );
    },
    methods: {
        switchUser() {
            this.$store.commit('clearLoginUser');
            this.$store.commit('setLoginPanel', 'EmailChecker');
        },
    },
};

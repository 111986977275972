var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v4 kt-login--signin",
      attrs: { id: "kt_login" },
    },
    [
      _c("div", {
        attrs: {
          id: "g_id_onload",
          "data-client_id": _vm.googleClientId,
          "data-callback": "handleCredentialResponse",
          "data-cancel_on_tap_outside": "true",
          "data-context": "signin",
        },
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor",
          staticStyle: { "background-image": "url(/images/bg-2.jpg)" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "kt-grid__item kt-grid__item--fluid kt-login__wrapper",
            },
            [
              _c(
                "div",
                { staticClass: "kt-login__container" },
                [
                  _c("div", { staticClass: "kt-login__logo" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _c("img", {
                        staticClass: "logo",
                        class: { rotateonce: _vm.spin },
                        attrs: { src: "/logos/sync-grades.svg" },
                      }),
                      _c("br"),
                      _c("img", {
                        attrs: { src: "/logos/logo-text-dark.svg" },
                      }),
                    ]),
                  ]),
                  _c(
                    "transition",
                    { attrs: { name: "fadeLeft" } },
                    [
                      _c("EmailChecker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.panel == "EmailChecker",
                            expression: "panel == 'EmailChecker'",
                          },
                        ],
                        staticStyle: { margin: "0 auto 4rem auto" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "fadeUp" } },
                    [
                      _c("PasswordLogin", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.panel == "PasswordLogin",
                            expression: "panel == 'PasswordLogin'",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "fadeUp" } },
                    [
                      _c("Redirect", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.panel == "Redirect",
                            expression: "panel == 'Redirect'",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "fadeUp" } },
                    [
                      _c("EmailSent", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.panel == "EmailSent",
                            expression: "panel == 'EmailSent'",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _vm.panel == "GoogleRedirect"
                    ? _c(
                        "transition",
                        { attrs: { name: "fadeUp" } },
                        [
                          _c("GoogleRedirect", {
                            attrs: { "google-client-id": _vm.googleClientId },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "transition",
                    { attrs: { name: "fadeRight" } },
                    [
                      _c("ForgotPassword", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.panel == "ForgotPassword",
                            expression: "panel == 'ForgotPassword'",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "fadeDown" } },
                    [
                      _c("ChangePassword", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.panel == "ChangePassword",
                            expression: "panel == 'ChangePassword'",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.error
        ? _c("div", { class: `alert alert-${_vm.errorVariant} mb-0` }, [
            _c("div", { staticClass: "alert-icon" }, [
              _c("i", { class: _vm.errorIcon }),
            ]),
            _c("div", { staticClass: "alert-text kt-font-bold" }, [
              _vm._v(" " + _vm._s(_vm.error) + " "),
            ]),
            _c("div", { staticClass: "alert-close" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "alert",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeErrorAlert()
                    },
                  },
                },
                [_vm._m(0)]
              ),
            ]),
          ])
        : _vm._e(),
      _c("PasswordModal", { ref: "password-change" }),
      _c(
        "b-alert",
        {
          staticClass: "position-fixed fixed-bottom m-0 rounded-0",
          staticStyle: { "z-index": "2000" },
          attrs: { variant: "danger", dismissible: "" },
        },
        [_c("p", { staticClass: "kt-font-bold" })]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", { staticClass: "la la-close" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
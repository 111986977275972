var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "kt-login__forgot kt-login__switch",
      staticStyle: { "animation-duration": "0.3s" },
    },
    [
      _vm._m(0),
      _c("form", { staticClass: "kt-form", attrs: { action: "" } }, [
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.passwordChange.change.password1,
                expression: "passwordChange.change.password1",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              autocomplete: "off",
              placeholder: "Enter a New Password",
            },
            domProps: { value: _vm.passwordChange.change.password1 },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.passwordChange.change,
                    "password1",
                    $event.target.value
                  )
                },
                _vm.checkPassword,
              ],
            },
          }),
        ]),
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.passwordChange.change.password2,
                expression: "passwordChange.change.password2",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              autocomplete: "off",
              placeholder: "Enter a New Password Again",
            },
            domProps: { value: _vm.passwordChange.change.password2 },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.passwordChange.change,
                    "password2",
                    $event.target.value
                  )
                },
                _vm.checkPassword,
              ],
            },
          }),
        ]),
        _c("div", { staticClass: "kt-login__actions" }, [
          !_vm.loading && !_vm.error && _vm.valid_password
            ? _c(
                "button",
                {
                  staticClass: "btn btn-brand btn-pill kt-login__btn-primary",
                  class: {
                    "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                  },
                  attrs: { id: "kt_login_forgot_submit" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" Save Password and Login ")]
              )
            : _c(
                "button",
                {
                  staticClass:
                    "btn btn-secondary btn-pill kt-login__btn-secondary ml-2",
                  attrs: { id: "kt_login_forgot_cancel" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.switchDisplay("EmailChecker")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-login__head" }, [
      _c("h3", { staticClass: "kt-login__title" }, [
        _vm._v(" Choose a Password "),
      ]),
      _c("div", { staticClass: "kt-login__desc" }, [
        _vm._v(" Choose a password to be used when logging into SyncGrades. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
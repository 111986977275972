import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=134abbc5"
import script from "./Login.vue?vue&type=script&lang=js"
export * from "./Login.vue?vue&type=script&lang=js"
import style0 from "./Login.css?vue&type=style&index=0&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('134abbc5')) {
      api.createRecord('134abbc5', component.options)
    } else {
      api.reload('134abbc5', component.options)
    }
    module.hot.accept("./Login.vue?vue&type=template&id=134abbc5", function () {
      api.rerender('134abbc5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/login/Login.vue"
export default component.exports
import PortletLoader from '../../components/PortletLoader.vue';
import network from '../../lib/network';

export default {
    name: 'PasswordModal',
    components: {
        PortletLoader,
    },
    data() {
        return {
            loading: false,
            error: null,
            saving: false,
            passwordResetGuid: null,
            studentGuardianEmail: null,
            registered: false,
            emailAddress: null,
            change: {
                password1: null,
                password2: null,
            },
            password_length: 0,
            contains_eight_characters: false,
            contains_number: false,
            contains_uppercase: false,
            contains_special_character: false,
            passwords_match: false,
            valid_password: false,
        };
    },
    methods: {
        open(guid) {
            // https://codepen.io/rauldronca/pen/OwKMGX
            const v = this;
            v.load(guid);
            v.$refs['password-modal'].show();
        },
        checkPassword() {
            this.password_length = this.change.password1.length;
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            if (this.password_length > 8) {
                this.contains_eight_characters = true;
            } else {
                this.contains_eight_characters = false;
            }

            this.contains_number = /\d/.test(this.change.password1);
            this.contains_uppercase = /[A-Z]/.test(this.change.password1);
            this.contains_special_character = format.test(this.change.password1);
            this.passwords_match = Boolean(this.change.password1 === this.change.password2);

            if (this.contains_eight_characters === true
                    && this.contains_special_character === true
                    && this.contains_uppercase === true
                    && this.passwords_match === true
                    && this.contains_number === true) {
                this.valid_password = true;
            } else {
                this.valid_password = false;
            }
        },
        save() {
            const v = this;
            if (v.saving) return;
            if (!v.valid_password) return;
            v.saving = true;
            const params = {
                passwordResetGuid: v.passwordResetGuid,
                studentGuardianId: v.studentGuardianId,
                emailAddress: v.emailAddress,
                password: v.change.password1,
            };
            network.auth.savePassword(params, (err) => {
                v.saving = false;
                if (err) {
                    v.showError(err);
                }

                network.auth.userLogin(params.emailAddress, params.password, (loginErr) => {
                    if (loginErr) v.showError(loginErr);
                    window.location.href = '/search';
                });
            });
        },
        load(guid) {
            const v = this;
            v.loading = true;
            v.error = null;

            network.auth.passwordGuidDetails(guid, (err, res) => {
                v.loading = false;
                if (err || !res.guardian) {
                    v.error = 'Invalid registration link';
                    return;
                }
                v.passwordResetGuid = res.guardian.passwordResetGuid;
                v.emailAddress = res.guardian.emailAddress;
                v.studentGuardianId = res.guardian.studentGuardianId;
                v.registered = res.guardian.registered;
            });
        },
    },
};

import network from '../../../lib/network';

export default {
    name: 'ChangePassword',
    components: {
    },
    props: {
        onValidEmailAddress: {
            type: Function,
        },
    },
    data() {
        return {
            checkPassword: null,
            valid_password: false,
            loading: false,
            error: null,
            passwordChange: {
                loading: false,
                error: null,
                saving: false,
                passwordResetGuid: null,
                studentGuardianEmail: null,
                registered: false,
                emailAddress: null,
                change: {
                    password1: null,
                    password2: null,
                },
                password_length: 0,
                contains_eight_characters: false,
                contains_number: false,
                contains_uppercase: false,
                contains_special_character: false,
                passwords_match: false,
                valid_password: false,
            },
        };
    },
    computed: {
        isValidEmail() {
            const v = this;
            return v.isValidEmailAddress(v.user.email);
        },
        userState() {
            return this.user.states.find((s) => s.active);
        },
        showPassword() {
            const v = this;
            return v.userState?.name === 'Access Pending Valid Password';
        },
    },
    methods: {
        switchDisplay(d) {
            this.display = d;
        },
        validateEmailDebounce() {
            const v = this;
            clearTimeout(v.debounce);
            v.user.loading = false;
            v.debounce = setTimeout(() => {
                v.validateEmail();
            }, 500);
        },
        validateEmail(param) {
            const v = this;
            const valid = v.isValidEmail;

            if (!valid || !v.user.email) {
                v.errors.email = 'Enter a valid email address';
                return;
            }
            v.user.loading = true;
            v.errors.email = null;

            if (param && param.force) {
                v.user.loading = true;
                network.auth.validateEmail(v.user.email, v.validateResponse);
            } else if (!valid) {
                v.user.loading = false;
            } else {
                if (v.user.checkedEmail == v.user.email) {
                    v.user.loading = false;
                    return;
                }
                v.user.loading = true;
                network.auth.validateEmail(v.user.email, v.validateResponse);
            }
        },
        validateResponse(err, resp) {
            const v = this;
            const thisEmail = String((v.user.email || '')).toLowerCase();
            v.user.loading = false;
            if (err) {
                v.user.checkedEmail = v.user.email;
                v.setUserState('Access Denied');
                v.onValidEmailAddress(null);
            } else if (resp.incomingEmail == thisEmail) {
                v.user.checkedEmail = resp.incomingEmail;
                v.setUserState(resp.status);
                v.onValidEmailAddress(v.user.checkedEmail);
            }
        },
        clearUserState() {
            const v = this;
            v.user.states = v.user.states.map((s) => {
                const state = s;
                state.active = false;
                return state;
            });
        },
        setUserState(newState) {
            const v = this;
            v.user.states = v.user.states.map((s) => {
                const state = s;
                state.active = Boolean(newState == s.name);
                return state;
            });
        },
        mouseOver() {
            this.spin = true;
        },
        mouseLeave() {
            this.spin = false;
        },
    },
};

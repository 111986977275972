<template>
<div
    id="kt_login"
    class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin"
>
    <div
        id="g_id_onload"
        :data-client_id="googleClientId"
        data-callback="handleCredentialResponse"
        data-cancel_on_tap_outside="true"
        data-context="signin"
    />

    <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        style="background-image: url(/images/bg-2.jpg);"
    >
        <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div class="kt-login__container">
                <div class="kt-login__logo">
                    <a href="#">
                        <img
                            src="/logos/sync-grades.svg"
                            :class="{'rotateonce': spin}"
                            class="logo"
                        >
                        <br>
                        <img src="/logos/logo-text-dark.svg">
                    </a>
                </div>

                <transition name="fadeLeft">
                    <EmailChecker
                        v-show="panel == 'EmailChecker'"
                        style="margin: 0 auto 4rem auto;"
                    />
                </transition>
                <transition name="fadeUp">
                    <PasswordLogin v-show="panel == 'PasswordLogin'" />
                </transition>
                <transition name="fadeUp">
                    <Redirect v-show="panel == 'Redirect'" />
                </transition>
                <transition name="fadeUp">
                    <EmailSent v-show="panel == 'EmailSent'" />
                </transition>

                <transition
                    v-if="panel == 'GoogleRedirect'"
                    name="fadeUp"
                >
                    <GoogleRedirect :google-client-id="googleClientId" />
                </transition>
                <transition name="fadeRight">
                    <ForgotPassword v-show="panel == 'ForgotPassword'" />
                </transition>
                <transition name="fadeDown">
                    <ChangePassword v-show="panel == 'ChangePassword'" />
                </transition>
            </div>
        </div>
    </div>

    <div
        v-if="error"
        :class="`alert alert-${errorVariant} mb-0`"
    >
        <div class="alert-icon">
            <i :class="errorIcon" />
        </div>
        <div class="alert-text kt-font-bold">
            {{ error }}
        </div>
        <div class="alert-close">
            <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                @click="closeErrorAlert()"
            >
                <span aria-hidden="true">
                    <i class="la la-close" /></span>
            </button>
        </div>
    </div>
    <PasswordModal ref="password-change" />
    <b-alert
        class="position-fixed fixed-bottom m-0 rounded-0"
        style="z-index: 2000;"
        variant="danger"
        dismissible
    >
        <p class="kt-font-bold" />
    </b-alert>
</div>
</template>
<script>
import { mapState } from 'vuex';
import * as net from '../../network';
import PasswordModal from './PasswordModal.vue';

import ChangePassword from './components/ChangePassword.vue';
import EmailChecker from './components/EmailChecker.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import PasswordLogin from './components/PasswordLogin.vue';
import Redirect from './components/Redirect.vue';
import GoogleRedirect from './components/GoogleRedirect.vue';
import EmailSent from './components/EmailSent.vue';

export default {
    name: 'Login',
    components: {
        PasswordModal,
        ChangePassword,
        EmailChecker,
        ForgotPassword,
        PasswordLogin,
        Redirect,
        GoogleRedirect,
        EmailSent,
    },
    data() {
        return {
            loading: false,
            spin: false,
            validatedEmail: null,
            googleClientId: '943688529011-sjccmuraglesri91rgcm8rtm5e4ub2p2.apps.googleusercontent.com',
        };
    },
    computed: {
        ...mapState({
            panel: (state) => state.login.panel,
            error: (state) => state.login.error,
            errorVariant: (state) => state.login.errorVariant,
            errorIcon: (state) => state.login.errorIcon,
        }),
        host() {
            if (window.location.href.indexOf('localhost') > -1) return 'http://localhost:3000';
            return `https://${window.location.host}`;
        },
    },
    mounted() {
        const v = this;

        v.activeSessionRedirect();

        v.$root.$on('bv::collapse::state', (id, shown) => {
            if (shown) {
                setTimeout(() => {
                    if (v.$refs.password) v.$refs.password.focus();
                }, 100);
            }
        });

        v.$root.$on('bv::modal::hide', () => {
            v.clearUserState();
        });
        if (window.location.hash) {
            v.$refs['password-change'].open(window.location.hash.substring(1));
        }

        window.handleCredentialResponse = function (r) {
            v.handleCredentialResponse(r);
        };

        v.loadGoogleJs();
    },
    methods: {

        loadGoogleJs() {
            const googleScript = document.createElement('script');
            googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
            document.head.appendChild(googleScript);
        },

        setEmail(email) {
            this.validatedEmail = email;
        },

        activeSessionRedirect() {
            net.session.refreshSession({}, (err, res) => {
                if (err) return;
                if (res?.userId) window.location.href = '/home';
            });
        },

        closeErrorAlert() {
            this.$store.commit('setLoginError', {
                message: null,
                variant: null,
                icon: null,
            });
        },

        showOneTapLoginError(err) {
            window.console.error(err);
            const params = {
                message: 'There was a problem locating that google account in our system, please enter your email address in the form above to try again.',
                variant: 'danger',
                icon: 'flaticon-warning',
            };
            this.$store.commit('setLoginError', params);
        },

        handleCredentialResponse(response) {
            const v = this;

            const params = {
                body: response,
            };

            // console.log(params);
            net.authGoogle.googleOneTapValidation(params, (err, res) => {
                if (err) {
                    return v.showOneTapLoginError(err);
                }
                if (res?.user?.userId) {
                    window.location.href = '/home';
                } else {
                    return v.showOneTapLoginError('An error occured on login');
                }
            });
        },
    },
};

</script>

<style src="./Login.css">

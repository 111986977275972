var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "kt-login__signin kt-login__switch",
      staticStyle: { "animation-duration": "0.3s" },
    },
    [
      _vm._m(0),
      _c(
        "form",
        {
          staticClass: "kt-form",
          attrs: { novalidate: "novalidate", onsubmit: "return false" },
        },
        [
          _c("div", { staticClass: "kt-login__actions kt-center" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-secondary btn-pill btn-lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.switchUser()
                  },
                },
              },
              [_c("i", { staticClass: "la la-user" }), _vm._v(" Switch User ")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-login__head" }, [
      _c(
        "h3",
        {
          staticClass: "kt-login__title",
          staticStyle: { "text-align": "center" },
        },
        [_c("div", { staticClass: "g-signin2", attrs: { id: "googleButton" } })]
      ),
      _c("div", { staticClass: "kt-login__desc" }, [
        _c("br"),
        _vm._v(" Click the button above to sign in with your google account. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-section__content kt-section__content--solid--" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { class: { "col-4 offset-5": !_vm.inline } }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-secondary btn-pill kt-spinner kt-spinner--left kt-spinner--md kt-spinner--primary",
              class: { inline: _vm.inline },
            },
            [_vm._v(" " + _vm._s(_vm.title || "Please wait...") + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="kt-section__content kt-section__content--solid--">
    <div class="row">
        <div :class="{'col-4 offset-5': !inline}">
            <button :class="{'inline': inline}" class="btn btn-secondary btn-pill kt-spinner kt-spinner--left kt-spinner--md kt-spinner--primary">
                {{ title || "Please wait..." }}
            </button>
        </div>
    </div>
</div>

</template>

<style scoped>
    button.kt-spinner {
        margin: 40px 0;
    }
    button.kt-spinner.inline {
        margin: 0 0 20px 0;
    }
</style>

<script>
export default {
  name: "PortletLoader",
  props: {
    inline: {
        type: Boolean,
        required: false
    },
    title: {
        type: String,
        required: false
    },
  }
};
</script>
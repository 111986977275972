<template>
<div
    style="animation-duration: 0.3s"
    class="kt-login__signin kt-login__switch"
>
    <div class="kt-login__head">
        <h3 class="kt-login__title">
            Sign in / Registration
        </h3>
    </div>
    <form
        class="kt-form"
        onsubmit="return false"
    >
        <div class="input-group">
            <div
                class="w-100"
                :class="{
                    'kt-spinner kt-spinner--sm kt-spinner--light kt-spinner--right kt-spinner--input': user.loading,
                }"
            >
                <input
                    v-model="email"
                    class="form-control is-invalid"
                    style="max-width: 430px;"
                    :class="{'valid-email': isValidEmail}"
                    type="text"
                    :placeholder="$t('generic.labels.email')"
                    name="username"
                    @keyup.enter="validateEmail({force: true})"
                    @keyup="validateEmailDebounce()"
                >
            </div>
        </div>

        <div class="kt-login__actions pt-4  justify-content-center">
            <p>Enter your email address above to get started</p>
            <div class="mb-4 kt-font-danger">
                {{ error || " " }}
            </div>

            <div class="row pt-2">
                <div class="col">
                    <button
                        style="height: 3.3rem"
                        class="btn btn-brand btn-pill m-2 kt-login__btn-primary"
                        @click="validateEmail({force: true})"
                    >
                        {{ user.loading ? 'Please Wait...' : 'Continue' }}
                    </button>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col">
                    <a
                        href="/auth/nyc/redirect"
                        style="height:3.5rem"
                        class="btn btn-primary btn-linkedin btn-pill pt-3"
                    >
                        <i class="fab fa-windows" />
                        Login with NYCDOE
                    </a>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col">
                    <div
                        style="width: 220px; margin: 0 auto;"
                        class="g_id_signin"
                        data-type="standard"
                        data-size="large"
                        data-text="sign_in_with"
                        data-shape="pill"
                        data-logo_alignment="center"
                    />
                </div>
            </div>
        </div>
    </form>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import network from '../../../lib/network';

export default Vue.extend({
    name: 'EmailChecker',
    data() {
        return {
            error: null,
            email: '',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            savedEmail: (state) => state.login.emailAddress,
        }),
        isValidEmail() {
            const v = this;
            return v.isValidEmailAddress(v.email);
        },
    },
    watch: {
        savedEmail() {
            this.email = this.savedEmail;
        },
    },
    methods: {

        switchDisplay(d) {
            this.$store.commit('setLoginPanel', d);
        },
        validateEmailDebounce() {
            const v = this;
            clearTimeout(v.debounce);

            v.debounce = setTimeout(() => {
                v.validateEmail();
            }, 500);
        },
        validateEmail(param) {
            const v = this;
            const valid = v.isValidEmail;
            v.error = null;
            if (v.email.length === 0) {
                v.error = 'Please enter a valid email address';
            }
            if (param && param.force) {
                return v.validateEmailDebounce();
            }
            if (valid) {
                v.processEmail();
            } else {
                v.error = 'Please enter a valid email address';
            }
        },
        processEmail() {
            const v = this;
            const thisEmail = String((v.email || '')).toLowerCase();

            network.auth.validateEmail(thisEmail, (err, resp) => {
                if (err) {
                    v.$store.commit('clearLoginUser');
                    v.switchDisplay('EmailChecker');
                    return v.showError('An error has occured');
                }

                // if (1 == 1) return console.log(resp);
                if (resp.incomingEmail == thisEmail) {
                    v.$store.commit('setLoginUserRedirect', resp.location);
                    v.$store.commit('setLoginUserEmailAddress', resp.incomingEmail);

                    if (resp.location) {
                        if (resp.location === '/login/email/sent') {
                            return v.switchDisplay('EmailSent');
                        }
                    }
                    v.switchDisplay('PasswordLogin');
                }
            });
        },
    },
});

</script>

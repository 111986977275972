var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "password-modal",
        attrs: {
          size: "lg",
          "no-close-on-esc": "",
          "no-close-on-backdrop": "",
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function ({ close }) {
              return [
                _c("h5", [_vm._v("Set Password")]),
                _c("button", {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return close()
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [
                _vm.loading
                  ? _c("PortletLoader")
                  : _vm.error
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-danger mb-0",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("div", { staticClass: "alert-icon" }, [
                          _c("i", { staticClass: "flaticon-warning" }),
                        ]),
                        _c("div", { staticClass: "alert-text" }, [
                          _vm._v(" This link is invalid or expired. "),
                        ]),
                      ]
                    )
                  : _c("form", { staticClass: "kt-form mb-3" }, [
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Enter a New Password")]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.change.password1,
                                    expression: "change.password1",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "password",
                                  autocomplete: "off",
                                  placeholder: "Password",
                                },
                                domProps: { value: _vm.change.password1 },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.change,
                                        "password1",
                                        $event.target.value
                                      )
                                    },
                                    _vm.checkPassword,
                                  ],
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v("Enter a New Password Again"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.change.password2,
                                    expression: "change.password2",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "password",
                                  autocomplete: "off",
                                  placeholder: "Confirm password",
                                },
                                domProps: { value: _vm.change.password2 },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.change,
                                        "password2",
                                        $event.target.value
                                      )
                                    },
                                    _vm.checkPassword,
                                  ],
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              {
                                staticClass: "alert alert-light",
                                attrs: { role: "alert" },
                              },
                              [
                                _c("div", { staticClass: "alert-icon" }, [
                                  !_vm.valid_password
                                    ? _c("i", {
                                        staticClass:
                                          "flaticon-warning kt-font-warning",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "flaticon2-check-mark kt-font-success",
                                      }),
                                ]),
                                _c("div", { staticClass: "alert-text" }, [
                                  _vm._v(" Password Requirements: "),
                                  _c("ul", { staticClass: "mt-3" }, [
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          is_valid:
                                            _vm.contains_eight_characters,
                                        },
                                      },
                                      [_vm._v(" 8 Characters ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          is_valid: _vm.contains_number,
                                        },
                                      },
                                      [_vm._v(" Contains Number ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          is_valid: _vm.contains_uppercase,
                                        },
                                      },
                                      [_vm._v(" Contains Uppercase ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          is_valid:
                                            _vm.contains_special_character,
                                        },
                                      },
                                      [_vm._v(" Contains Special Character ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          is_valid: _vm.passwords_match,
                                        },
                                      },
                                      [_vm._v(" Both Passwords Match ")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
              ]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function ({ close }) {
              return [
                !_vm.loading && !_vm.error && _vm.valid_password
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-info pull-right",
                        class: {
                          "kt-spinner kt-spinner--sm kt-spinner--light":
                            _vm.saving,
                        },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v(" Save Password and Login ")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-warning pull-right",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return close()
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div
    style="animation-duration: 0.3s"
    class="kt-login__forgot1 kt-login__switch"
>
    <div class="kt-login__head">
        <h3 class="kt-login__title">
            Forgotten Password ?
        </h3>
        <div class="kt-login__desc">
            Enter your email to reset your password:
        </div>
    </div>
    <form
        class="kt-form"
        action=""
    >
        <div class="input-group">
            <input
                v-model="emailAddress"
                class="form-control"
                type="text"
                :placeholder="$t('generic.labels.email')"
                name="username"
                @keyup.enter="validateEmail({ force: true })"
            >
        </div>
        <div class="kt-login__actions">
            <button
                id="kt_login_forgot_submit"
                class="btn btn-brand btn-pill kt-login__btn-primary"
                @click.stop.prevent="validateEmail({ force: true })"
            >
                Request
            </button>&nbsp;&nbsp;
            <button
                id="kt_login_forgot_cancel"
                class="btn btn-secondary btn-pill kt-login__btn-secondary"
                @click.stop.prevent="switchDisplay('EmailChecker')"
            >
                Cancel
            </button>
        </div>
    </form>
</div>
</template>

<script>
import { mapState } from 'vuex';
import network from '../../../lib/network';

export default {
    name: 'ForgotPassword',
    data() {
        return {
            emailAddress: null,
            loading: false,
            debounce: null,
        };
    },
    computed: {
        ...mapState({
            email: (state) => state.login.emailAddress,
            redirect: (state) => state.login.redirect,
        }),
    },
    watch: {
        email() {
            this.emailAddress = this.email;
        },
    },
    methods: {
        validateEmailDebounce() {
            const v = this;
            clearTimeout(v.debounce);
            v.debounce = setTimeout(() => {
                v.validateEmail();
            }, 500);
        },
        validateEmail() {
            const v = this;
            const valid = v.isValidEmailAddress(v.emailAddress);
            if (!valid || !v.emailAddress) return v.showError('Enter a valid email address');
            v.loading = true;
            network.auth.forgotPassword(v.emailAddress, v.validateResponse);
        },
        validateResponse(err) {
            const v = this;
            v.loading = false;
            if (err) return v.showError('An error has occured.');
            v.showAlert('A password reset link has been sent to your email address.', 'Reset Password link Sent', 'success');
        },
        switchDisplay(d) {
            this.$store.commit('setLoginPanel', d);
        },
    },
};

</script>

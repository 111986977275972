var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "kt-login__signin kt-login__switch",
      staticStyle: { "animation-duration": "0.3s" },
    },
    [
      _vm._m(0),
      _c(
        "form",
        {
          staticClass: "kt-form",
          attrs: { novalidate: "novalidate", onsubmit: "return false" },
        },
        [
          _c(
            "div",
            {
              staticClass: "kt-login__actions",
              staticStyle: { "padding-top": "40px" },
            },
            [
              _vm.redirect === "/auth/nyc/redirect"
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btn-pill btn-lg",
                      attrs: { href: _vm.redirect },
                    },
                    [
                      _c("i", { staticClass: "fab fa-windows" }),
                      _vm._v(" Login with NYCDOE "),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-brand btn-pill kt-login__btn-primary",
                      attrs: { href: _vm.redirect },
                    },
                    [_vm._v(" Login ")]
                  ),
              _c("br"),
              _c(
                "a",
                {
                  staticClass: "btn btn-secondary btn-pill btn-lg mt-5",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.switchUser()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "la la-user" }),
                  _vm._v(" Switch User "),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-login__head" }, [
      _c("h3", { staticClass: "kt-login__title" }, [_vm._v(" Saml Redirect ")]),
      _c("div", { staticClass: "kt-login__desc" }, [
        _c("br"),
        _vm._v(" Click the login button below "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'Redirect',
    data() {
        return {
        };
    },
    computed: {
        ...mapState({
            redirect(state) {
                return state.login.redirect;
            },
            email: (state) => state.login.emailAddress,
            nyc: (state) => state.login.redirect === '',
        }),
        nyc() {

        },
        host() {
            if (window.location.href.indexOf('localhost') > -1) {
                return 'http://localhost:3000';
            }
            return '';
        },
    },
    methods: {
        ...mapMutations([
            'setLoginUser',
        ]),
        switchUser() {
            this.$store.commit('clearLoginUser');
            this.$store.commit('setLoginPanel', 'EmailChecker');
        },
        switchDisplay(d) {
            this.$store.commit('setLoginPanel', d);
        },
    },
};

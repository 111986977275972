var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "kt-login__signin kt-login__switch",
        staticStyle: { "animation-duration": "0.3s" },
      },
      [
        _c("div", { staticClass: "kt-login__head" }, [
          _c("h3", { staticClass: "kt-login__title kt-font-light" }, [
            _vm._v(" Email Sent "),
          ]),
        ]),
        _c("div", { staticClass: "row kt-login__extra" }, [
          _c(
            "div",
            {
              staticClass: "col kt-font-light kt-font-bold",
              staticStyle: { "text-align": "center" },
            },
            [
              _vm._v(" An email has been sent to your email address. "),
              _c("br"),
              _c("br"),
              _vm._v(" Check your email for the activation link. "),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
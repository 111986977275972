<template>
<div
    style="animation-duration: 0.3s"
    class="kt-login__signin kt-login__switch"
>
    <div class="kt-login__head">
        <h3 class="kt-login__title kt-font-light">
            Email Sent
        </h3>
    </div>
    <div class="row kt-login__extra">
        <div
            class="col kt-font-light kt-font-bold"
            style="text-align: center"
        >
            An email has been sent to your email address.
            <br>
            <br>
            Check your email for the activation link.
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'EmailSent',
};

</script>

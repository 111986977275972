import { mapState } from 'vuex';
import network from '../../../lib/network';

export default {
    name: 'PasswordLogin',

    data() {
        return {
            password: null,
        };
    },
    computed: {
        ...mapState({
            email: (state) => state.login.emailAddress,
            panel: (state) => state.login.panel,
            error: (state) => state.login.error,
            errorVariant: (state) => state.login.errorVariant,
            errorIcon: (state) => state.login.errorIcon,
        }),
    },
    methods: {
        switchDisplay(d) {
            this.$store.commit('setLoginPanel', d);
        },
        closeErrorAlert() {
            this.$store.commit('setLoginError', {
                message: null,
                variant: null,
                icon: null,
            });
        },
        showLoginError(err) {
            window.console.error(err);
            const params = {
                message: 'Invalid user name or password, please try again',
                variant: 'danger',
                icon: 'flaticon-warning',
            };
            this.$store.commit('setLoginError', params);
        },
        checkForm(e) {
            e.preventDefault();
            this.$store.commit('setLoginError', {
                message: null,
                variant: null,
                icon: null,
            });
            if ((this.password || '').length < 6) {
                this.showLoginError('Password too short');
                return false;
            }
            network.auth.logIn(this.email, this.password, (err, res) => {
                if (err) return this.showLoginError(err);
                if (res.status == 'ok') {
                    window.location.href = '/home';
                }
            });
        },
    },
};

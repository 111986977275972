var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "kt-login__signin kt-login__switch",
      staticStyle: { "animation-duration": "0.3s" },
    },
    [
      _vm._m(0),
      _c(
        "form",
        { staticClass: "kt-form", attrs: { onsubmit: "return false" } },
        [
          _c("div", { staticClass: "input-group" }, [
            _c(
              "div",
              {
                staticClass: "w-100",
                class: {
                  "kt-spinner kt-spinner--sm kt-spinner--light kt-spinner--right kt-spinner--input":
                    _vm.user.loading,
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                  ],
                  staticClass: "form-control is-invalid",
                  class: { "valid-email": _vm.isValidEmail },
                  staticStyle: { "max-width": "430px" },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("generic.labels.email"),
                    name: "username",
                  },
                  domProps: { value: _vm.email },
                  on: {
                    keyup: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.validateEmail({ force: true })
                      },
                      function ($event) {
                        return _vm.validateEmailDebounce()
                      },
                    ],
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "kt-login__actions pt-4 justify-content-center" },
            [
              _c("p", [
                _vm._v("Enter your email address above to get started"),
              ]),
              _c("div", { staticClass: "mb-4 kt-font-danger" }, [
                _vm._v(" " + _vm._s(_vm.error || " ") + " "),
              ]),
              _c("div", { staticClass: "row pt-2" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-brand btn-pill m-2 kt-login__btn-primary",
                      staticStyle: { height: "3.3rem" },
                      on: {
                        click: function ($event) {
                          return _vm.validateEmail({ force: true })
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.user.loading ? "Please Wait..." : "Continue"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._m(1),
              _vm._m(2),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-login__head" }, [
      _c("h3", { staticClass: "kt-login__title" }, [
        _vm._v(" Sign in / Registration "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row pt-2" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-linkedin btn-pill pt-3",
            staticStyle: { height: "3.5rem" },
            attrs: { href: "/auth/nyc/redirect" },
          },
          [
            _c("i", { staticClass: "fab fa-windows" }),
            _vm._v(" Login with NYCDOE "),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row pt-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", {
          staticClass: "g_id_signin",
          staticStyle: { width: "220px", margin: "0 auto" },
          attrs: {
            "data-type": "standard",
            "data-size": "large",
            "data-text": "sign_in_with",
            "data-shape": "pill",
            "data-logo_alignment": "center",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }